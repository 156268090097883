/* You can add global styles to this file, and also import other style files */
@import 'https://library-sdb.apps.bancolombia.com/bds/7.0.0/bds.min.css';

.w-c-auto {
    width: auto !important;
}
.h-c-auto {
    height: auto !important;
}
.max-w-c-100 {
    max-width: 100px;
}
.max-w-c-170 {
    max-width: 170px;
}
.max-w-c-220 {
    max-width: 220px;
}
.max-w-c-380 {
    max-width: 380px;
}
.not-display {
    display: none;
}
.ta-c-right {
    text-align: -webkit-right;
}
.bc-c-scroll {
    max-height: 67vh;
    overflow-y: auto;
}
